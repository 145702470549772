import React from 'react'
import ReactWOW from 'react-wow'
import partner1 from '../../assets/images/logoinstaloop.png'
import partner2 from '../../assets/images/empire.jpeg'
import partner3 from '../../assets/images/about/nexflay.png'
import partner4 from '../../assets/images/codiviller.png'
import partner5 from '../../assets/images/hdgrup.png'
import partner6 from '../../assets/images/WACHATBOTpreto.png'
import { Link } from 'gatsby'

const Partner = () => {
    return (
        <div className="pt-100 pb-0">
            <div className="container">
                <h1 className='text-center'>We're in the Business of Building Trust & Innovation
                </h1>
                <p className='text-center'>Creating cutting-edge AI & Blockchain solutions.
                Some of our trusted clients include:
                </p>
                <div style={{ paddingTop: '30px' }} className="row align-items-center">
                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <Link to='/case-study-empire-distributions'>
                                    <img style={{ opacity: 0.9 }} src={partner2} alt="empire" title='empire' />
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <Link to='/case-study-instaloop'>
                                    <img style={{ opacity: 0.7 }} src={partner1} alt="instaloop" title='instaloop' />
                                </Link>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <Link to='/case-study-next-departure'>
                                        <img src={partner3} alt="Next Departure - Cheap Flights" title='Next Departure - Cheap Flights' />
                                    </Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <Link to='/case-study-cvre'>
                                        <img src={partner4} alt="Condoville Real Estate" title='Condoville Real Estate' />
                                    </Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <Link to='/case-study-hd-group'>
                                        <img style={{ opacity: 0.5 }} src={partner5} alt="HD Group - Worker's App" title='HD Group - Workers App' />
                                    </Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <Link to='https://www.wachatbot.ai/'>
                                        <img style={{ opacity: 0.5 }} src={partner6} alt="Wachat bot" title='Wachat bot' />
                                    </Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                </div>
            </div>

            <div className="divider"></div>
        </div>
    )
}

export default Partner