import React from 'react'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeaturedServices = () => {
  return (
    <section className="services-area pt-50 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>Our team combines deep technological knowledge with industry-specific insights to deliver solutions that align with your business goals.</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon1} alt="Web Development" title='Web Development' />
              </div>
              <h3>AI using API</h3>
              <p className='text-center'>
                Custom AI Text/Voice/Image/Video<br />
                AI Integrate live Data<br />
                Private AI, Self hosted<br />
                Data Processing & Training<br />
                AI updates and maintenance<br />
              </p>
            </div>
          </div>


          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon3} alt="iOS/Android" title='iOS/Android' />
              </div>
              <h3>Data Migrations</h3>
              <p className='text-center'>SQL & NoSQL<br />
                Cloud to On-Premise<br />
                Cross-Platform Compatibility<br />
                Backups and Security<br />
                Data Integrity<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon4} alt="Backend" title='Backend' />
              </div>
              <h3>Process Re-engineering</h3>
              <p className='text-center'>Business Process Automation<br />
              Legacy System Upgrades<br />
                Workflow Redesign<br />
                Data-Driven Optimization<br />
                Compliance & Security<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon5} alt="Marketing" title='Marketing' />
              </div>
              <h3>Blockchain Development</h3>
              <p className='text-center'>Smart Contracts<br />
                Decentralized Applications (DApps)<br />
                Token & NFT Development<br />
                Web2 & Web3 Integrations<br />
                Contract Audits<br /></p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon6} alt="Crypto Development" title='Crypto Development' />
              </div>
              <h3>Team Augmentation</h3>
              <p className='text-center'>AI or Blockchain Specialist<br />
                Onsite / Virtual<br />
                Results or it's Free<br />
                Code share upon completion<br />
                Progress reports<br /></p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box equal-height">
              <div className="icon">
                <img src={serviceIcon2} alt="Webview" title='Webview' />
              </div>
              <h3>Custom Automation</h3>
              <p className='text-center'>Custom AI or Traditonal Macros<br />
                Bulk action tools<br />
                Browser or App Automation<br />
                Social Media Automation<br />
                Data Processing Automation<br /></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurFeaturedServices