import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowItsWork from '../../assets/images/how-its-work.png'
import { Link } from 'gatsby';

const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            {/* <span className="sub-title">
                                <img src={StarIcon} alt="image" />
                                Our Process
                            </span> */}
                            <h2>Our Process</h2>
                            <p className='text-justify'>Are you considering hiring Astute Visions for your next web development project? <br />
                                Here’s an overview of our detailed workflow</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Day 1</h3>
                                    <p className='text-justify'>During your free consultation,
                                        speak to a Astute Visions web developer
                                        for a preliminary discussion of your goals.
                                        We’ll chat about the scope of your project,
                                        the services you’re interested in,
                                        and your timeline.
                                        By the time we end the consultation,
                                        we’ll have a plan in place
                                        with next steps.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Day 2 - 3</h3>
                                    <p className='text-justify'>After your consultation,
                                        our team will sit down and begin
                                        mapping out your
                                        custom web development plan.
                                        We’ll create an initial draft
                                        of the requirements
                                        and send it to you as a document</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Day 3 - 7</h3>
                                    <p className='text-justify'>We welcome your feedback once you
                                        receive the requirements document.
                                        We’ll continue ideating with you
                                        until you’re pleased
                                        with the project scope.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Day 7+</h3>
                                    <p className='text-justify'>We’ll finalize the project scope
                                        and produce a work agreement
                                        for you to sign.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        5
                                    </div>
                                    <h3>Development Begins</h3>
                                    <p className='text-justify'>The project officially begins.
                                        We’ll share a presentation with you
                                        as our team hits each developmental
                                        milestone until completion.</p>
                                </div>
                                <div className="btn-box" style={{ marginTop: "30px", marginLeft: "80px" }}>
                                    <Link to="tel:14168386065" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        Book Consultation
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowItsWork} alt="image" />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;