import React, { useEffect } from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/programmer-amico.png'

const Banner = () => {
    useEffect(() => {
        const scrollToSection = () => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    setTimeout(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                    }, 250); // Delay ensures the page is fully loaded
                }
            }
        };

        scrollToSection(); // Run on initial page load
        window.addEventListener("hashchange", scrollToSection); // Run on hash change

        return () => window.removeEventListener("hashchange", scrollToSection);
    }, []);

    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1 style={{ fontSize: 30 }}>Astute Visions - Empowering Businesses with AI & Blockchain Innovation
                                </h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>We specialize in delivering tailored Artificial Intelligence & Blockchain solutions to give you the competitive edge driving higher margins, efficiency and security.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="tel:14168386065" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        Free Consultation
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={bannerImg} alt="Custom AI & Blockchain Development" title='Custom AI & Blockchain Development' />
                            </div>
                        </ReactWOW>
                    </div>

                    <div id="services-section">.</div>
                </div>
            </div>
        </div >
    )
}

export default Banner